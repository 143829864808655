import { Injectable } from '@angular/core';
import { LoyaltyRedemptionRequest } from '../dto/loyalty-redemption-request';
import { LoyaltyRedemptionResponse } from '../dto/loyalty-redemption-response';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyRedemtionServiceService {
  constructor(private httpClient: HttpClient) {}

  loyaltyRedemption(loyaltyRedemptionRequest: LoyaltyRedemptionRequest, host) {
    return this.httpClient.post<LoyaltyRedemptionResponse>(
      'https://' + host + '/subscriber/OCLoyaltyRedemption.mqrm',
      loyaltyRedemptionRequest
    );
  }
}
