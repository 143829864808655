import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MaterialModule } from "../app/material/material.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app.component";
import { LoyaltyRedemptionComponent } from "./loyalty-redemption/loyalty-redemption.component";
import { RouterModule } from "@angular/router";
import { CouponInquiryComponent } from "./coupon-inquiry/coupon-inquiry.component";
import { AddUpdateComponent } from "./add-update/add-update.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { VerificationOtpComponent } from "./verification-otp/verification-otp.component";
import { ProfileComponent } from "./profile/profile.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import * as $ from "jquery";
import * as bootstrap from "bootstrap";

@NgModule({
  declarations: [
    AppComponent,
    LoyaltyRedemptionComponent,
    CouponInquiryComponent,
    AddUpdateComponent,
    VerificationOtpComponent,
    ProfileComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      { path: "loyalty-redemption", component: LoyaltyRedemptionComponent },
      { path: "add-update", component: AddUpdateComponent },
      { path: "verification-otp", component: VerificationOtpComponent },
      { path: "profile", component: ProfileComponent },
    ]),
    HttpClientModule,
    NgbModule,
  ],

  providers: [{ provide: Window, useValue: window }],
  bootstrap: [AppComponent],
})
export class AppModule {}
