<form id="myForm">
  <div id="model-content">
    <!-- <div class="modal-header">
          <div class="row">
              <h1>OptCulture</h1>
          </div>
      </div> -->
    <div class="modal-body inheritOverflow pt-0">
      <div class="row">
        <div class="brand">
          <h3>OptCulture</h3>
        </div>
        <h3 class="br-sub">Redeem Loyalty</h3>
      </div>

      <div>
        <table width="100%">
          <tr>
            <td class="header">Customer Name</td>
            <td>{{ customerName }}</td>
          </tr>
          <tr>
            <td class="header">Invoice Amount</td>
            <td>{{ billValue }}</td>
          </tr>
          <tr>
            <td class="header">Membership #</td>
            <td>{{ customerMembershipNumber }}</td>
          </tr>
        </table>
      </div>
      <br />
      <div>
        <div class="row" style="padding-top: 25px; padding-bottom: 25px">
          <div class="col-md-12" style="text-align: center">
            Customer has a reward balance of
            <b>₹{{ customerTotalRedeemableBalance }}</b>

            <b>, {{ loyaltyPoints }} Points</b>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" style="text-align: center">
            <div
              class="form-inline"
              style="
                padding-bottom: 25px;
                justify-content: center;
                padding-top: 25px;
              "
            >
              <div class="form-group mb-2"></div>
              <div class="form-group mx-sm-3 mb-2">
                <!--<input #redeemPointsTbInput value="{{redeemPointsTbInputValue}}" style="width: 200px;text-align:center; font-size:xx-large" class="form-control" />-->
                <input
                  #redeemPointsTbInput
                  style="
                    width: 200px;
                    text-align: center;
                    font-size: xx-large;
                    height: 40px;
                  "
                  class="form-control"
                />
              </div>
              <button
                type="submit"
                (click)="loyaltyRedemptionDialog()"
                class="btn btn-primary mb-2"
                *ngIf="enableRedeemApply"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="coupons">
        <table style="width: 100%" class="table table-hover">
          <thead>
            <tr style="text-align: left">
              <th scope="col">SELECT</th>
              <th scope="col">COUPON</th>
              <th scope="col">CODE</th>
              <th scope="col">DESCRIPTION</th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="let data of customerCouponResponseArray"
              class="table-active"
            >
              <td>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    (change)="radioChangeForCoupon($event, data.OTPENABLED)"
                    class="custom-control-input"
                    value="{{ data.COUPONCODE }}"
                    id="{{ data.COUPONNAME }}"
                    name="disabledGroupExample"
                  />
                  <label
                    class="custom-control-label"
                    for="{{ data.COUPONNAME }}"
                  ></label>
                </div>
              </td>
              <th scope="row" style="text-align: left">
                {{ data.COUPONNAME }} <BR />
              </th>
              <td style="font-size: smaller">{{ data.COUPONCODE }}</td>
              <td style="font-size: smaller">
                <b>{{ data.COUPONTYPE }} :</b> {{ data.DESCRIPTION }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <p><strong>Or</strong><br />Enter Coupon Code</p>

        <input
          #redeemCouponTbInput
          value="{{ redeemPointsTbInputValue }}"
          (change)="manualCouponOnchange($event)"
          style="
            width: 200px;
            text-align: center;
            font-size: xx-large;
            height: 40px;
          "
          class="form-control"
        />
      </div>

      <input
      #redeemCouponTbInputOTP
        type="hidden"
        name="redemptionResponse"
       /><input
      type="hidden"
      id="redemptionResponse"
      name="redemptionResponse"
      value="{{ redemptionResponse }}"
    />
    </div>
    <div></div>
    <div class="modal-footer">
      <button
        type="submit"
        (click)="couponRedemptionDialog()"
        class="btn btn-primary mb-2"
        *ngIf="!enableRedeemApply"
      >
        Apply
      </button>
      <!--button
        value="Continue"
        (click)="couponSubmit(errorCode, message)"
        class="btn btn-primary mb-2"
      >
        Continue
      </button-->
    </div>
  </div>
</form>



<ng-template
  class="modal fade bd-example-modal-lg4"
  #content
  id="exampleModalCenter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myLargeModalLabel"
  aria-hidden="true"
  let-modal
>
  <div style="text-align: center" class="modal-header">
    <h5>Please Enter the Verification Code Received on Email and/or SMS</h5>
    
  </div>
  <div style="text-align: center" class="modal-body">
    <form
      id="otp-form"
      enctype="multipart/form-data"
      autocomplete="off"
      data-hasrequired="* Required Fields"
      novalidate="novalidate"
    >
      <label class="label" for="otp">
        <h5>Verification Code</h5>
      </label>
      <div class="control">
        <input
          name="otp"
          id="otp"
          title="Verification Code"
          #otpCode
          class="input-text otp"
          type="text"
          data-validate="{required:true}"
          aria-required="true"
        />
      </div>
      <input type="hidden" name="success" id="success" value="0" />
      <p class="text-danger pt-3" *ngIf="otpStatus">{{ otpStatus }}</p>
    </form>
  </div>
  <div class="modal-footer v2">
    <button
      class="btn btn-secondary mb-2"
      (click)="modalRef.close('Close click')"
      id="closebutton"
      #closebutton
      data-role="closeBtn"
      type="button"
    >
      Close
    </button>
    <button
      type="button"
      (click)="loyaltyOTP()"
      class="btn btn-primary"
    >
      Resend OTP
    </button>
    <button
      type="button"
      (click)="loyaltyOTPAcknowledge(otpCode)"
      class="btn btn-primary"
    >
      Submit
    </button>
    <!-- <button type="button" #closebutton class="close" data-dismiss="modal">&times;</button> -->
  </div>
</ng-template>

<ng-template #message let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ status }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p [ngClass]="{ 'text-danger': status === 'Failure' }">
      {{ statusMessage }}
    </p>
  </div>

  <div class="modal-footer">
    <button
      mat-button
      class="btn btn-primary"
      (click)="modalSuccess.close('Close click')"
    >
      Close
    </button>
  </div>
</ng-template>

<ng-template #couponRedemtionConfirm let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>Do you want to proceed with redemption?</p>
  </div>

  <div class="modal-footer">
    <button
      mat-button
      class="btn btn-primary"
      (click)="cpRedemption(redeemCouponTbInput, message)"
    >
      OK
    </button>
    <button
      mat-button
      class="btn btn-primary"
      (click)="modalRedemtionConfirmation.close('Close click')"
    >
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #redemtionConfirm let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>Do you want to proceed with redemption?</p>
  </div>

  <div class="modal-footer">
    <button
      mat-button
      class="btn btn-primary"
      (click)="redemption(redeemPointsTbInput, message)"
    >
      OK
    </button>
    <button
      mat-button
      class="btn btn-primary"
      (click)="modalCouponRedemtionConfirmation.close('Close click')"
    >
      Cancel
    </button>
  </div>
</ng-template>
<footer>
  <img src="/assets/images/Group23.png" alt="" class="powered" />
</footer>
