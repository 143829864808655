<!DOCTYPE html>
<html lang="en" dir="ltr">

<head>
  <meta charset="utf-8">
  <title></title>
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
</head>

<body>
  <section class="second-row-section">
    <div class="row">
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-12">
            <div [style.background-image]="'url(/assets/images/' + tierImg + ')'" class="contact-teir-div col 2 {{tierLevel}}">
              <div class="contact-teir-div-content">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="AJ-profile">
                      {{firstName.charAt(0)}}{{lastName.charAt(0)}}
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <p class="profile-name">{{firstName}} {{lastName}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">

          <div class="col-12">
            <div class="alert alert-warning aretwrnw">
              <span class="ac"  >Tier : {{tierName}}</span>
            </div>
          </div>
          <div class="col-5 ltgreysm"  *ngIf="statusExpiry">
            <p>Expiry:</p>
          </div>
          <div class="col-7"  *ngIf="statusExpiry">
            <p>{{statusExpiry}}</p>
          </div>
          
          <div class="col-5 ltgreysm"  *ngIf="phoneNumber">
            <p>Phone:</p>
          </div>
          <div class="col-7"  *ngIf="phoneNumber">
            <p>{{phoneNumber}}</p>
          </div>
          <div class="col-5 ltgreysm"  *ngIf="emailAddress">
            <p>Email:</p>
          </div>
          <div class="col-7"  *ngIf="emailAddress">
            <p style="word-wrap: break-word;">{{emailAddress}}</p>
          </div>
          <div class="col-5 ltgreysm"  *ngIf="birthDay">
            <p>Birthday:</p>
          </div>
          <div class="col-7" *ngIf="birthDay">
            <p>{{birthDay | date}}</p>
          </div>
          <div class="col-5 ltgreysm" *ngIf="anniversary">
            <p>Anniversary:</p>
          </div>
          <div class="col-7" *ngIf="anniversary">
            <p>{{anniversary | date}}</p>
          </div>
          <div class="col-5 ltgreysm"  *ngIf="customerMembershipNumber">
            <p>Loyalty ID:</p>
          </div>
          <div class="col-7"  *ngIf="customerMembershipNumber">
            <p>{{customerMembershipNumber}}</p>
          </div>
          <div class="col-md-12 d-none d-sm-block">
            <button type="button" data-toggle="modal"  data-target="#update-modal"  (click)="open(content)"  id=btn-update class="btn btn-danger btn-sm">Update</button>&nbsp;&nbsp; 
            <button class="btn btn-primary  btn-sm" data-toggle="modal" (click)="moreInfo(info)" data-target=".bd-example-modal-lg">More <img src="/assets/images/Icon_ionic-ios-arrow-round-forward.svg" alt=""></button>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <nav ngbNav #nav="ngbNav" class="nav-tabs">
          <ng-container ngbNavItem>
            <a ngbNavLink class="navLink">Loyalty Details</a>
            <ng-template ngbNavContent>
              <div class="gradient" *ngIf="nextTierMilestone" >
                
                <div class="level-total">
                  <p class="text-left away-text">You are <strong>{{nextTierMilestone}}</strong> away from {{nextTierName}}</p>
                  <div class="level-outer">
                    <div class="level-inner"  [style.width]="this.percentBar + '%'" >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 silver-name">
                      {{tierName}}
                    </div>
                    <div class="col-6 gold-name">
                      {{nextTierName}}
                    </div>
                  </div>
                  

                <hr>
                <div class="row">
                  <div class="col-6 text-left">Last Transaction:</div>
                  <div class="col-6 text-right"><strong>{{lastTrnsDate.substring(0, 10) | date}}</strong></div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-6 text-left">Lifetime Purchase Value</div>
                  <div class="col-6 text-right"><strong>{{lifeTimePurchaseVal|currency:'INR':true}}</strong></div>
                </div>
                <hr>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="white-box">
                    <div class="money-current">
                      <p><span class="money-green">{{loyaltyAmount |currency:'INR':true}}<br> {{loyaltyPoints}} Points</span><br>Loyalty balance</p>
                      </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="white-box">
                    <div class="money-current">
                      <p><span class="money-green">{{totalRedeemableCurrency|currency:'INR':true}}</span><br>Redeemable balance</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="white-box">
                    <div class="money-current">
                      <p><span class="money-red">{{loyaltyAmountExpire|currency:'INR':true}}, {{loyaltyPointsExpire}} Points</span><br>Expiring this month</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="white-box">
                    <div class="under-level">
                      <p class="Loyalty">
                        Loyalty Program rules: 
                      <!-- <p class="see-teir" data-toggle="modal" (click)="openTier(tier)" data-target=".bd-example-modal-lg4">
                        See all tiers <img src="/assets/images/Icon_ionic-ios-arrow-round-forward.svg" alt="">
                      </p> -->
                      <span class="earn">{{issuanceDesc}}</span>
                      <span class="under-earn">{{redeemDesc}}</span></p>
                      <!-- <p class="bonuses-available" data-toggle="modal" data-target=".bd-example-modal-lg1">Bonuses Available <img src="/assets/images/Icon_ionic-ios-arrow-round-forward.svg" alt=""></p> -->
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem>
            <a ngbNavLink class="navLink">Current offers</a>
            <ng-template ngbNavContent>
              <div *ngFor="let data of customerCouponResponseArray" class="pt-3 white-box">
                <p class="vouchers-img-content">{{data.COUPONTYPE}} <br> <strong>{{data.DESCRIPTION}} {{data.COUPONCODE}} . Add Item to Avail Discount.</strong></p>
      
              </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem>
            <a ngbNavLink class="navLink">Transaction History</a>
            <ng-template ngbNavContent>
              <div class="rowhead">
                <div class="row">
                  <div class="col-12 col-sm-4">DATE & RECEIPT NO.</div>
                  <div class="col-6 col-sm-3">TRX TYPE</div>
                  <div class="col-6 col-sm-3">BALANCE UPDATE</div>
                  <div class="d-none d-sm-block col-sm-2">VIEW</div>
                </div>
              </div>
              <div  *ngFor="let data of loyaltyMemberTransaction" class="pt-3 white-box">
                <div class="row">
                  <div class="col-12 col-sm-4">{{data.date.substring(0, 10) | date}}<br class="d-none d-sm-block "> {{data.receiptNumber}}</div>
                  <div class="col-6 col-sm-3">{{data.amount.type}}</div>
                  <div class="col-6 col-sm-3"><span  *ngIf="data.balances[0].difference">{{data.balances[0].difference}} Points </span><span *ngIf="data.balances[1].difference">₹{{data.balances[1].difference}}</span></div>
                  <div class="d-none d-sm-block col-sm-2"><a  *ngIf="data.eReceiptURL" href="{{data.eReceiptURL}}" target="_blank">View Receipt</a><span *ngIf="!data.eReceiptURL" >NA</span></div>
                </div>
              </div>
              <!-- <div class="transaction-button-modal">
                <button type="button" class="btn btn-danger" data-toggle="modal" data-target=".bd-example-modal-lg3"  *ngIf="nextEnabled" (click)="next(loyaltyMemberTransaction)">Next Page</button>
                <button type="button" class="btn btn-outline-primary" data-toggle="modal" data-target=".bd-example-modal-lg3" *ngIf="prevEnabled"  (click)="prev(loyaltyMemberTransaction)">Previous Page</button>
              </div> -->
            </ng-template>
          </ng-container>
        </nav>
        
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </section>


<ng-template let-modal class="modal fade bd-example-modal-lg" #info  tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div>
    <div class="modal-content">

      <div class="modal-body">
        <p style="text-align:right;cursor:pointer" (click)="modal.dismiss('Cross click')"><i class="fa fa-times fa-2x"></i></p>
        <p class="more-information">More Information :</p>
        <table class="modal-table">
          <tr>
            <td class="modal-body-bold">ADDRESS</td>
            <td class="aa">:</td>
            <td>{{address1}} {{address2}} {{city}} {{state}} {{country}} {{zip}}</td>
          </tr>
          <tr>
            <td class="modal-body-bold">MEMBER SINCE</td>
            <td class="aa">:</td>
            <td>{{createdDate | date}}</td>
          </tr>
          <!-- <tr>
            <td class="modal-body-bold">FIRST CHILD'S NAME</td>
            <td class="aa">:</td>
            <td>Value</td>
          </tr>
          <tr>
            <td class="modal-body-bold">FIRST CHILD'S BIRTHDAY</td>
            <td class="aa">:</td>
            <td>Value</td>
          </tr>
          <tr>
            <td class="modal-body-bold">SECOND CHILD'S NAME</td>
            <td class="aa">:</td>
            <td>Value/Zip</td>
          </tr>
          <tr>
            <td class="modal-body-bold">ANY UDF NAME</td>
            <td class="aa">:</td>
            <td>Value</td>
          </tr> -->
        </table>
      </div>

    </div>
  </div>
</ng-template>


<div class="modal fade bd-example-modal-lg1 " id="closea" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered bonus-dialog">
    <div class="modal-content">

      <div class="modal-body  bonus-modal">
      <p style="text-align:right;cursor:pointer" onclick="closeq()"><i class="fa fa-times fa-2x"></i></p>
        <p class="bonuses-available-red">Bonuses Available</p>
        <table class="bonus-table">
          <tr>
            <td style="text-align:center;font-weight:bold;font-size:25px;">Reasons</td>
            <td style="text-align:center;font-weight:bold;font-size:25px;">Bonus</td>
          </tr>
          <tr>
            <td class="">On reaching leve of <strong>$1000.00 </strong> of Lifetime purchase value</td>
            <td class="bonus-center-table">$10.00</td>
          </tr>
          <tr>
            <td class="">On reaching leve of <strong>$5000.00 </strong> of Lifetime purchase value</td>
            <td class="bonus-center-table">$50.00</td>
          </tr>
          <tr>
            <td class="">On reaching leve of <strong>$100.00 </strong> of Lifetime purchase value</td>
            <td class="bonus-center-table">$5.00</td>
          </tr>
          <tr>
            <td class="">On Enrollment</td>
            <td class="bonus-center-table">$5.00</td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</div>



<ng-template let-modal #transactionHistory class="modal fade bd-example-modal-lg2" id="transactiontotalmodal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div>
    <div class="modal-content">

      <div class="modal-body  transaction-modal">
        <p style="text-align:right;cursor:pointer" (click)="modal.dismiss('Cross click')"><i class="fa fa-times fa-2x"></i></p>
        <p class="transaction-history-red">Transaction History</p>
        <table class="transaction-table">
          <tr class="transaction-table-black-background">
            <td>Date</td>
            <td>Transaction</td>
            <td> Loyalty Earned</td>
            <td>Receipt No.</td>
            <td></td>
          </tr>
          <tbody class="transaction-body">
            <tr  *ngFor="let data of loyaltyMemberTransaction">
              <td class="transaction-date">{{data.date.substring(0, 10) | date}}</td>
              <td class="transaction-amount">{{data.amount.type}}</td>
              <td class="transaction-point"><span  *ngIf="data.balances[0].difference">{{data.balances[0].difference}} Points </span><span *ngIf="data.balances[1].difference">₹{{data.balances[1].difference}}</span>  </td>
              <td class="transaction-no">{{data.receiptNumber}}</td>
              <td class="transaction-receipt"><a  *ngIf="data.eReceiptURL" href="{{data.eReceiptURL}}" target="_blank">View Receipt</a><span *ngIf="!data.eReceiptURL" >NA</span></td>
            </tr>
          </tbody>
        </table>
        <!-- <div class="transaction-button-modal">
          <button type="button" class="btn btn-danger" data-toggle="modal" data-target=".bd-example-modal-lg3"  *ngIf="nextEnabled" (click)="next(loyaltyMemberTransaction)">Next Page</button>
          <button type="button" class="btn btn-outline-primary" data-toggle="modal" data-target=".bd-example-modal-lg3" *ngIf="prevEnabled"  (click)="prev(loyaltyMemberTransaction)">Previous Page</button>

        </div> -->

      </div>

    </div>
  </div>
</ng-template>

<div class="modal fade bd-example-modal-lg3" id="transactiontotalmodalsec" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered transaction-dialog">
    <div class="modal-content">

      <div class="modal-body  transaction-modal">
        <p style="text-align:right;cursor:pointer" onclick="closeq()"><i class="fa fa-times fa-2x"></i></p>
        <p class="transaction-history-red">Transaction History</p>
        <table class="transaction-table">
          <tr class="transaction-table-black-background">
            <td>Date</td>
            <td>Transaction</td>
            <td> Loyalty Earned</td>
            <td>Receipt No.</td>
            <td></td>
          </tr>
          <tbody class="transaction-body">
            <tr>
              <td class="transaction-date">18 Nov 2020</td>
              <td class="transaction-amount">$952.4</td>
              <td class="transaction-point">202 Points 73.1 Currency</td>
              <td class="transaction-no">110</td>
              <td class="transaction-receipt">View Receipt</td>
            </tr>
          </tbody>
        </table>
        <div class="transaction-button-modal">
          <button type="button" class="btn btn-danger" data-toggle="modal" data-target=".bd-example-modal-lg2" onclick="closeq()">Next Page</button>
          <button type="button" class="btn btn-outline-primary" data-toggle="modal" data-target=".bd-example-modal-lg2" onclick="closeq()">Previous Page</button>

        </div>

      </div>

    </div>
  </div>
</div>

<ng-template #tier class="modal fade bd-example-modal-lg4" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered teir-dialog">
    <div class="modal-content">

      <div class="modal-body  teir-modal">
        <p style="text-align:right;cursor:pointer" onclick="closeq()"><i class="fa fa-times fa-2x"></i></p>
        <table class="teir-table">
          <tr class="teir-table-black-background">
            <td>Privilege Teir</td>
            <td>Privilege Name</td>
            <td> Earn Rule</td>
            <td>Redeem Rule</td>
            <td>Teir Upgrade Rule</td>
          </tr>
          <tbody class="teir-body">
            <tr>
              <td class="teir-date">Teir 1</td>
              <td class="teir-amount">Silver</td>
              <td class="teir-point">1 Point for <strong> $1.00</strong></td>
              <td class="teir-no"> <strong>$10.00</strong>  for <strong> 100 </strong>points</td>
              <td class="teir-receipt">Cross-over at <strong>$10000.00</strong> </td>
            </tr>
            <tr>
              <td class="teir-date">Teir 2</td>
              <td class="teir-amount">Gold</td>
              <td class="teir-point">1 Point for <strong> $1.00</strong></td>
              <td class="teir-no"> <strong>$10.00</strong>  for <strong> 200 </strong>points</td>
              <td class="teir-receipt">Cross-over at <strong>$20000.00</strong> </td>
            </tr>
            <tr>
              <td class="teir-date">Teir 3</td>
              <td class="teir-amount">Platinum</td>
              <td class="teir-point">1 Point for <strong> $1.00</strong></td>
              <td class="teir-no"> <strong>$10.00</strong>  for <strong> 300 </strong>points</td>
              <td class="teir-receipt"></td>
            </tr>

          </tbody>
        </table>

      </div>

    </div>
  </div>
</ng-template>




<ng-template #content let-modal class="modal fade bd-example-modal-lg5" id="update-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div>
    <div class="modal-content">

      <div class="modal-body  form-modal">

        <form>
          <div class="AJ-profile">
            {{firstName.charAt(0)}}{{lastName.charAt(0)}}
          </div>
  <div class="form-row form-group form-modal-total-div">
    <div class="col">
      <input type="text" class="form-control" #updateFirstName placeholder="First name" value="{{firstName}}">
    </div>
    <div class="col">
      <input type="text" class="form-control" #updateLastName placeholder="Last name"  value="{{lastName}}">
    </div>
  </div>
  <div class="form-group">
  <input type="text" class="form-control" id="formGroupExampleInput" #updateMobileNumber placeholder="Mobile Number" value="{{mobile}}">
</div>
<div class="form-group">
  <input type="text" class="form-control" id="formGroupExampleInput2" #updateEmailAddress placeholder="Email Address" value="{{emailAddress}}">
</div>
<div class="form-row">
  <div class="col">
    <input type="text" class="form-control" #updateDOB placeholder="DOB in {{dobformat}}" value="{{birthDay | date: 'MM/dd/yyyy'}}">
  </div>
  <div class="col">
    <input type="text" class="form-control" #updateAnniveraryDate placeholder="Anniverary Date in {{anniversaryFormat}}" value="{{anniversary | date: 'MM/dd/yyyy'}}">
  </div>
</div>
<p class="additional-information">Additional Information :</p>
<div class="form-group">
<input type="text" class="form-control" #updateAddressLine1  id="formGroupExampleInput" placeholder="Address Line 1" value="{{address1}}">
</div>
<div class="form-group">
<input type="text" class="form-control" #updateAddressLine2 id="formGroupExampleInput2" placeholder="Address Line 2" value="{{address2}}">
</div>
<div class="form-row form-group">
<div class="col">
  <input type="text" class="form-control"  #updateCity placeholder="City" value="{{city}}">
</div>
<div class="col">
  <input type="text" class="form-control" #updateState placeholder="State" value="{{state}}">
</div>
</div>
<div class="form-row form-group">
<div class="col">
  <input type="text" class="form-control"  #updateCountry placeholder="Country" value="{{country}}">
</div>
<div class="col">
  <input type="text" class="form-control"  #updateZIP placeholder="Postal Code/ZIP" value="{{zip}}">
</div>
</div>
<p class="additional-information">More Information :</p>
<div class="form-row form-group">
<div class="col">
<input type="text" class="form-control" placeholder="First Child's Name">
</div>
<div class="col">
<input type="text" class="form-control" placeholder="First Child's Birthday">
</div>
</div>
<div class="form-row form-group">
<div class="col">
<input type="text" class="form-control" placeholder="Second Child's Name">
</div>
<div class="col">
<input type="text" class="form-control" placeholder="Any UDF Name">
</div>
</div>
<p class="modal-form-bottom-p">Member Since :{{createdDate | date: 'MMMM d, y'}}</p>
<h3 *ngIf="proUpresp" class="text-center">{{proUpresp}}</h3>
<div class="modal-form-button">
<button type="button" class="btn btn-outline-primary" id="closebutton"
#closebutton (click)="modal.dismiss('cancel click');cancel()">Cancel</button>
<button type="button" class="btn btn-danger" (click)="updateContacts(updateFirstName,updateLastName
,updateMobileNumber,updateEmailAddress,updateDOB,updateAnniveraryDate,updateAddressLine1,updateAddressLine2,updateCity,updateState,updateCountry,updateZIP)">Save</button>

</div>
</form>

      </div>
    </div>
  </div>
</ng-template>

  <script type="text/javascript">

    function cancel(){
      document.getElementById("update-modal").classList.remove("show");
      $('.modal-backdrop').remove();
    }
    function closeq(){
      $('.modal').modal('hide');
    }
  </script>

  <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
</body>

</html>
